
import { ref, computed } from "vue";

const credentials = {
	endpoint: "https://gateway.storjshare.io",
	accessKey: "jx4shzgugbsehedxhetynw44kypq",
	secretKey: "jyuxktc37qb5avum4tmb3wf34kwd5dyylmjd7rnxkjzg2opcuwjqu"
} as const;

const base = `https://api.threedrive.dev`;

type ObjectsResponse = {
	key: string;
	size: number;
	location: string;
}[];

export default {
	setup: () => {
		const objects = ref<ObjectsResponse | undefined>();
		const thumbnails = ref<{ [key: string]: string }>({});

		const loadThumbnails = async () => {
			if (objects.value == undefined) return;

			const objectsWithoutThumbnail = objects.value
				.map((object) => object.location)
				.filter((location) => thumbnails.value[location] === undefined);

			if (objectsWithoutThumbnail.length === 0) return true;

			const response = await fetch(`${base}/thumbnails`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					objects: objectsWithoutThumbnail
				})
			});

			const thumbnailsResponse = await response.json();

			for (const { object, thumbnail } of thumbnailsResponse) {
				thumbnails.value[object] = thumbnail;
			}

			return false;
		};

		(async () => {
			const response = await fetch(`${base}/objects`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					...credentials,
					path: "photos-demo"
				})
			});

			objects.value = await response.json();

			while ((await loadThumbnails()) == false) {}
		})();

		return {
			objects,
			thumbnails
		};
	}
};
