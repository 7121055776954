<template>
	<footer>Built by Monty + Kindred.</footer>
</template>

<style scoped>
footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	background: #252034;

	font-size: 1rem;
	text-align: center;

	padding: 0.75rem 0;
}
</style>
