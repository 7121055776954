<template>
	<nav>
		<h1><router-link to="/">threedrive</router-link></h1>
	</nav>

	<div class="padding">&nbsp;</div>
</template>

<style scoped>
nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	height: 60px;
	padding: 0 4rem;
	background: linear-gradient(269.13deg, #690dff 0%, #0116d2 100%);

	display: flex;
	flex-direction: row;
	align-items: center;
}

.padding {
	height: 60px;
}

a {
	color: inherit;
	text-decoration: none;
}

h1 {
	font-weight: 300;
	color: white;
	letter-spacing: -3%;
}
</style>
