
import ObjectTree from "../components/ObjectTree.vue";
import ObjectGrid from "../components/ObjectGrid.vue";
import Help from "../components/Help.vue";

export default {
	components: {
		ObjectTree,
		ObjectGrid,
		Help
	}
};
