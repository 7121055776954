<template>
	<div class="help">
		<p>
			Welcome!<br /><br />
			ThreeDrive lets you decide where you store your files.<br /><br />
			We just help you access them.<br /><br />

			<b>Why are my files loading?</b><br />
			We’re generating thumbnails for your files right now.<br /><br />

			<b>Why are my files loading?</b><br />
			We’re generating thumbnails for your files right now.<br /><br />
		</p>
	</div>
</template>

<style scoped>
.help {
	background: #2a283c;
	padding: 3rem 2rem;
}

p {
	font-style: normal;
	font-weight: 300;
	font-size: 1rem;
	line-height: 175%;
	/* or 35px */

	letter-spacing: -0.03em;
}
</style>
