<template>
	<div class="object-tree">
		<p>All Files</p>
	</div>
</template>

<style scoped>
.object-tree {
	background: #28282d;

	padding: 3rem 2rem;
}
</style>
