<template>
	<main>
		<div class="landing">
			<h2>Store your files in any cloud you like.</h2>

			<div class="form">
				<input placeholder="Endpoint" />
				<input placeholder="Access Key" />
				<input placeholder="Secret Key" />

				<router-link to="/dashboard">
					<button>Open my files!</button>
				</router-link>
			</div>
		</div>
	</main>
</template>

<style scoped>
main {
	margin-top: 7rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
}

.landing {
	width: 90%;
	max-width: 550px;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4rem;
}

h2 {
	display: block;
	width: 100%;
	max-width: 450px;

	font-weight: 400;
	font-size: 2.5rem;
	letter-spacing: 0.05rem;
	text-align: center;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
}

input,
input::placeholder {
	color: rgb(212, 212, 212);
	font-size: 1.25rem;
	font-weight: 300;
}

input {
	padding: 0.8rem 1.75rem;
	background: #333241;
	border-radius: 15px;
	border: none;
	color: white;
}

button {
	padding: 1rem 1.75rem;
	background: #690dff;
	border-radius: 15px;
	border: none;
	color: white;

	font-weight: 600 !important;
	font-size: 1rem;
	cursor: pointer;

	transition: filter 0.15s;

	width: 100%;
}

button:hover {
	filter: brightness(1.15);
}

button:active {
	filter: brightness(0.8);
}
</style>
